<template>
  <div class="page-account">
    <Layout>
      <Header class="title">
        <div style="width:200px;display:flex;align-items:center;"><img style="width:100%;" src="@/assets/images/logo01.jpg?t=123" alt=""></div>

      </Header>
      <Layout>
        <Content>
          <div class="topHead">
            <span>广西桂之保汽车服务有限公司</span>
            <div style="width:100%;margin-left:22%" class="name">—— 激情！创新！专业！认真！为您提供专业贴心的服务</div>
          </div>
          <div class="introduce">
            <div class="content">
              <div class="contentTitle">桂之保 业内领先</div>
              <div class="contentVal"></div>
              <div class="con">
                <img src="@/assets/images/01.jpg">
              </div>
              <div class="con">
                <div class="conTitle">广西桂之保汽车服务有限公司（简称桂之保）作为广西机动车延长保修服务的专业服务商，于2021年在广西柳州、百色、梧州、河池、南宁等地区陆续开展业务，经过不断耕耘桂之保已成为销售额超百万的企业。</div>
                <div class="conCon">
                  桂之保以服务客户需求为导向，为客户与经销商带来更多利益为宗旨，以打造延保行业领军企业为公司使命。“诚实做人，认真做事，精诚团结，勇于拼搏”是桂之保的精神。桂之保期待成为您忠实的朋友和合作伙伴，共创互惠互赢的未来！
                </div>
              </div>
            </div>
          </div>
          <div class="introduce_b">
            <div class="contentdi">
              <div class="ent">
                <div class="entImg">
                  <img src="@/assets/images/02.jpg">
                </div>
                <div class="link">
                  <div class="linkTit">诚实做人</div>
<!--                  <div class="linkCon">。</div>-->
                </div>
              </div>
              <div class="ent">
                <div class="entImg">
                  <img src="@/assets/images/03.jpg">
                </div>
                <div class="link">
                  <div class="linkTit">认真做事</div>
<!--                  <div class="linkCon">装备轻量化和新能源产业的发展，以及节能减排战略要求的继续实施，镁合金行业将迎来投资热潮。山西恒镁新材料科技有限公司着眼于国内、国际市场的旺盛需求，结合企业自身的优势及能力，投资建设镁合金制造的相关生产线，加大对镁合金在武器装备、航空航天、交通器械等领域的轻量化应用，力争在技术、规模、产能等方面达到国内行业领先的水平。</div>-->
                </div>
              </div>
              <div class="ent">
                <div class="entImg">
                  <img src="@/assets/images/04.jpg">
                </div>
                <div class="link">
                  <div class="linkTit">精诚团结</div>
<!--                  <div class="linkCon">泡沫铝产业作为新兴产业与绿色工业，受到国家政策大力倡导与扶植，也是山西省“十四五规划”中十四个战略性新兴产业规划之一。中实强业（山西）新材料科技有限公司位于山西省综改示范区，致力于球体泡沫铝功能材料、球体泡沫铜功能材料、多孔泡沫金属复合材料的研制、开发和生产。其子公司——北京中实强业泡沫金属有限公司，是国内少有的能够稳定量产大尺寸开孔型球体泡沫铝公司，作为泡沫铝国家标准制定参编单位之一，是泡沫铝国家标准制定的参照物，也是符合军工要求的重要金属材料。</div>-->
                </div>
              </div>
              <div style="margin:0 24px" class="ent">
                <div class="entImg">
                  <img src="@/assets/images/05.jpg">
                </div>
                <div class="link">
                  <div class="linkTit">勇于拼搏</div>
<!--                  <div class="linkCon">山西菲斯数字技术有限公司以大数据产业为核心，以煤炭产业为依托，致力于煤炭行业数字化产业升级、煤机制造行业数字化赋能以及向政府提供大数据支撑。依托渠道资源和行业经验，为煤矿企业、城市、交通等提供智能化、数字化运维平台。智能矿山建设贯穿于煤矿全生命周期管理，将各安全生产管理和信息系统进行集中的业务融合，通过矿山“智能中心管控平台”建设，整合各管控子系统，以信息数据数字化语言为手段，以BIM/GIS三维场景化孪生为基础底板，打通人机壁垒，打通信息孤岛，打通监管屏障，对安全生产管理水平，减耗增效、节约人力成本、生产成本等各个方面都有着不可估量的巨大潜力。</div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="registered">
            <div class="hasRegistered">已经注册？</div>
            <div id="loginBtn" class="loginBtn" @click="openModel">登录</div>
            <!--                    <div class="hasRegistered toRegistered">立即注册</div>-->
            <!--                  <div class="registeredForm">-->
            <!--                    <fieldset>-->
            <!--                      <legend class="area">地区</legend>-->
            <!--                      <div data-toggle="distpicker">-->
            <!--                        <div class="form-group">-->
            <!--                          <select class="form-control" @change="getProvince" v-model="province">-->
            <!--                            <option v-for="(item,index) in addresData" :key="index" :value="index">{{item.label}}</option>-->
            <!--                          </select>-->
            <!--                        </div>-->
            <!--                        <div class="form-group">-->
            <!--                          <select class="form-control" @change="getCity" v-model="city">-->
            <!--                            <option v-for="(item,index) in addresData[province].children" :key="index" :value="index">{{item.label}}</option>-->
            <!--                          </select>-->
            <!--                        </div>-->
            <!--                        <div class="form-group">-->
            <!--                          <select class="form-control" @change="getArea" v-model="area">-->
            <!--                            <option v-for="(item,index) in addresData[province].children[city].children" :key="index" :value="index">{{item.label}}</option>-->
            <!--                          </select>-->
            <!--                        </div>-->
            <!--                      </div>-->
            <!--                    </fieldset>-->
            <!--                    <fieldset :class="focus == '2'?'fie':''">-->
            <!--                      <legend class="lastName" v-if="focus == '2'" :class="focus == '2'?'fieColor':''">姓名</legend>-->
            <!--                      <Input id="lastName" v-model="register.name" class="formRegistered" type="text" placeholder="姓名*" @on-focus="onfocus('2')" @on-blur="focus='0'"/>-->
            <!--                    </fieldset>-->

            <!--                    <fieldset :class="focus == '3'?'fie':''">-->
            <!--                      <legend class="email" v-if="focus == '3'" :class="focus == '3'?'fieColor':''">电子邮件</legend>-->
            <!--                      <Input id="email" v-model="register.email" class="formRegistered" type="text" placeholder="电子邮件*" @on-focus="onfocus('3')" @on-blur="focus='0'"/>-->
            <!--                    </fieldset>-->

            <!--                    <fieldset :class="focus == '4'?'fie':''">-->
            <!--                      <legend class="company" v-if="focus == '4'" :class="focus == '4'?'fieColor':''">公司</legend>-->
            <!--                      <Input id="company" v-model="register.company" class="formRegistered" type="text" placeholder="公司*" @on-focus="onfocus('4')" @on-blur="focus='0'"/>-->
            <!--                    </fieldset>-->

            <!--                    <fieldset :class="focus == '5'?'fie':''">-->
            <!--                      <legend class="phone" v-if="focus == '5'" :class="focus == '5'?'fieColor':''">电话</legend>-->
            <!--                      <Input id="phone" v-model="register.phone" class="formRegistered" type="text" placeholder="电话*" @on-focus="onfocus('5')" @on-blur="focus='0'"/>-->
            <!--                    </fieldset>-->

            <!--                    <fieldset>-->
            <!--                      <legend class="relationship">与互联造物的关系</legend>-->
            <!--                      <select id="relationship" v-model="register.relationship"  class="selectpicker">-->
            <!--                        <option role="option" selected="selected" style="display: none"></option>-->
            <!--                        <option value="REL10" role="option">互联造物员工</option>-->
            <!--                        <option value="REL6" role="option">顾问</option>-->
            <!--                        <option value="REL4" role="option">合作伙伴</option>-->
            <!--                        <option value="REL2" role="option">客户</option>-->
            <!--                        <option value="REL7" role="option">媒体/分析师</option>-->
            <!--                        <option value="REL5" role="option">潜在合作伙伴</option>-->
            <!--                        <option value="REL3" role="option">潜在客户</option>-->
            <!--                        <option value="REL9" role="option">实习生</option>-->
            <!--                        <option value="REL8" role="option">投资者/股东</option>-->
            <!--                      </select>-->
            <!--                    </fieldset>-->
            <!--                    <div class="loginBtn" @click="registerSub">提交</div>-->
            <!--                  </div>-->
          </div>
        </Content>
      </Layout>
      <Footer>
        <div>广西桂之保汽车服务有限公司   © 2018-2026</div>
       <div class="dibu" ><a href="https://beian.miit.gov.cn" target="_blank" style="color:#fff"><img src="@/assets/images/ba.png" alt="">&nbsp; 桂ICP备2022006976号-1</a></div>
      </Footer>
    </Layout>
    <div class="main" v-if="showModel">
      <div class="loginHome">
        <div class="loginTop">
          <span>感谢您对广西桂之保汽车服务有限公司的关注</span>
          <img id="close" src="@/assets/images/close.png" @click="closeModle">
        </div>
        <div class="loginTit">
          一次注册,永久访问
        </div>
        <div class="loginBody">
          <div class="login-group">
            <Form ref="formInline" :model="formInline" :rules="ruleInline"
                  @keyup.enter="handleSubmit('formInline')">
              <FormItem prop="username">
                <Input type="text" v-model="formInline.username" prefix="ios-contact-outline"
                       placeholder="请输入用户名" size="large"/>
              </FormItem>
              <FormItem prop="password">
                <Input type="password" v-model="formInline.password" prefix="ios-lock-outline"
                       placeholder="请输入密码" size="large"/>
              </FormItem>
              <FormItem>
                <Button class="submitBtn" @click="handleSubmit('formInline')">登录</Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <Modal v-model="modals" scrollable footer-hide closable title="请完成安全校验" :mask-closable="false" :z-index="2"
           width="342">
      <div class="captchaBox">
        <div id="captcha" style="position: relative" ref="captcha"></div>
        <div id="msg"></div>
      </div>
    </Modal>
  </div>
</template>
<script>
  import city from '@/utils/city'
  import { AccountLogin } from '@/api/interFace'
  import { registerInfo } from "../../api/register"
  import {setCookies,removeCookies} from '@/utils/helper'
  import '../../assets/js/canvas-nest.min'
  export default {
    data () {
      return {
        focus: '0',
        province: '0',
        city: '0',
        area: '0',
        addresData: city,
        showModel: false,
        modals: false,
        fullWidth: document.documentElement.clientWidth,
        swiperOption: {
          pagination: '.swiper-pagination',
          autoplay: true
        },
        autoLogin: true,
        formInline: {
          username: '',
          password: ''
        },
        ruleInline: {
          username: [
            {required: true, message: '请输入用户名', trigger: 'blur'}
          ],
          password: [
            {required: true, message: '请输入密码', trigger: 'blur'}
          ]
        },
        register: {
          province: '北京',
          city: '东城区',
          area: '',
          name: '',
          phone: '',
          email: '',
          company: '',
          relationship: '',
          type: 2,
          domain_url: window.location.href,
        }
      }
    },
    created () {
      var _this = this
      top !== window && (top.location.href = location.href)
      document.onkeydown = function () {
        if (_this.$route.name === '登录') {
          let key = window.event.keyCode
          if (key === 13) {
            _this.handleSubmit('formInline')
          }
        }
      }
      window.addEventListener('resize', this.handleResize)
    },
    watch: {
      fullWidth (val) {
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if (!this.timer) {
          // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
          this.screenWidth = val
          this.timer = true
          let that = this
          setTimeout(function () {
            // 打印screenWidth变化的值
            that.timer = false
          }, 400)
        }
      }
    },
    mounted: function () {
    },
    beforeCreate() {
      window.document.title = "【广西桂之保汽车服务有限公司】电子合同、电子签名、电子签章、印章管理、数据存证paap系统服务平台"
    },
    methods: {
      registerSub(){
        registerInfo(this.register).then(res=>{
          this.$Message.success(res.msg)
          this.reset()
        }).catch(res=>{
          this.$Message.error(res.msg)
        })
      },
      onfocus  (that) {
        this.focus = that;
      },
      reset(){
        this.register.province = '北京'
        this.register.city = '东城区'
        this.register.area = ''
        this.register.name = ''
        this.register.phone = ''
        this.register.email = ''
        this.register.company = ''
        this.register.relationship = ''
        this.province= '0'
        this.city= '0'
        this.area= '0'
      },
      // 关闭模态框
      closeModel () {
        let msg = this.$Message.loading({
          content: '登录中...',
          duration: 0
        })
        AccountLogin({
          username: this.formInline.username,
          password: this.formInline.password
        }).then(res => {
          msg()
          let data = res.data
          if (data.userinfo.status == 0) {
            let expires = this.getExpiresTime(data.expires_time)
            // 登陆信息
            setCookies('token', data.token, expires)
            setCookies('expires_time', data.expires_time, expires)
            setCookies('uuid', data.userinfo.id, expires)
            setCookies('version', data.version_status)
            this.$router.replace({ path: '/index' })
            this.$store.commit('setMenus', data.menus)
            this.$store.commit('setAuth', data.unique_auth)
            this.$store.commit('setUserInfo', data.userinfo)
            this.$store.commit('setVersion', data.version_status)
          } else {
            removeCookies('token')
            removeCookies('expires_time')
            this.$Message.error('您已被限制登录')
          }
        }).catch(res => {
          msg()
          let data = res === undefined ? {} : res
          this.$Message.error(data.msg || '登录失败')
        })
      },
      openModel () {
        this.showModel = true
      },
      closeModle () {
        this.showModel = false
      },
      getProvince () {
        this.register.province = city[this.province].label
        this.register.city = city[this.province].children[this.city].label
        if (city[this.province].children[this.city].children) {
          this.register.area = city[this.province].children[this.city].children[this.area].label
        }else{
          this.register.area = ''
        }
        this.city = '0';
        this.area = '0';
      },
      getCity () {
        this.register.city = city[this.province].children[this.city].label
        this.register.area = city[this.province].children[this.city].children[this.area].label
        this.area = '0';
      },
      getArea(){
        this.register.area = city[this.province].children[this.city].children[this.area].label
      },
      getExpiresTime (expiresTime) {
        let nowTimeNum = Math.round(new Date() / 1000)
        let expiresTimeNum = expiresTime - nowTimeNum
        return parseFloat(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24)
      },
      handleSubmit (name) {
        this.$refs[name].validate((valid) => {
          if (valid) {
            this.closeModel()
          }
        })
      }
    },
  }
</script>
<style scoped>
  .fieColor {
    color : #1A73E8;
  }
  .fie {
    border:1px solid  #1A73E8;
  }
  .code {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .code .pictrue {
    height: 40px;
  }
  .selectpicker {
    width: 100%;
    border: none;
    outline: none;
  }
  input[type=text]:focus{
    outline: none;
    border: 1px solid #fff;
  }
  .loginTop span {
    float: left;
  }
  .m-b {
    margin-bottom: 15px;
  }
  .input-group {
    position: relative;
    display: table;
    border-collapse: separate;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .loginBody {
    width: 100%;
    background: #fff;
  }
  .login-group {
    margin-top: 50px;
    padding: 0 60px 60px;
    color: #111123;
  }
  .main {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.75);
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    color: #FFF;
    text-align: center;
    height:100vh
  }
  .loginHome {
    width: 600px;
    background: #ffffff;
    display: inline-block;
    margin-top: 100px;
  }
  .loginTop img {
    width: 20px;
    margin-top: 15px;
  }
  .loginTit {
    width: 100%;
    height: 100px;
    line-height: 100px;
    background: #008FD3;
    font-size: 30px;
  }
  .loginTop {
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #000;
    text-align: right;
    font-size: 14px;
    padding: 0 10px;
  }
  .page-account {
    width: 100%;
    height: 100vh;
    overflow: auto;
  }
  .title {
    display:flex;
    height:64px;
    width:100%;
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
  }
  .ivu-layout-header,.ivu-layout-footer {
    background-color : #000000;
  }
  .ivu-layout-header {
    padding: 0 190px;
  }
  .ivu-layout-footer {
    color:#ffffff;
    text-align :center;
  }
  .topHead {
    height:340px;
    width:100%;
    background-image: linear-gradient(to right, #222222 , #545454);
    padding-top: 100px;
    padding-left: 190px;
    font-size: 45px;
    color: #ffffff;
  }
  .name {
    color: #F0AB00;
    margin-left:10px;
  }
  .introduce {
    width: 100%;
    height: auto;
    background: #F6F6F6;
    padding: 100px 0px;
  }
  .content {
    width: 50%;
    margin: 0px 40% 0px 10%;
    min-width: 960px;
    display: flow-root;
  }
  .contentTitle {
    color: #000000;
    font-size: 40px;
    text-align: center;
    width: 60%;
    margin: 0px 20%;
    min-width: 580px;
  }
  .contentVal {
    color: #000000;
    font-size: 20px;
    text-align: center;
    width: 60%;
    margin: 10px 20%;
    min-width: 580px;
  }
  .con {
    width: 50%;
    float: left;
    padding: 30px 0;
  }
  .con img {
    width: 80%;
    margin: 0px 10%;
  }
  .conTitle {
    font-size: 20px;
  }
  .conCon {
    line-height: 25px;
    margin-top: 15px;
    font-size: 15px;
  }
  .introduce_b {
    width: 100%;
    height: auto;
    padding: 0px;
    background: #ffffff;
  }
  .contentdi {
    width: 100%;
    padding: 0px 0px 0 5%;
    min-width: 960px;
    display: flex;
    box-sizing:border-box
  }
  .ent {
    width: 30%;
    float: left;
    text-align: center;
  }
  .entImg {
    width: 100%;
    margin: 20px 0 0px;
  }
  .entImg img {
    width: 40%;
  }
  .link {
    width: 100%;
    margin: 0px  0 40px 0px;
    text-align: center;
  }
  .linkTit {
    font-size: 18px;
  }
  .linkCon {
    margin-top: 10px;
    font-size: 14px;
  }
  .dibu{
    display:flex;
    align-items:center;
    justify-content:center
  }
  .registered {
    width: 350px;
    height: auto;
    background: #ffffff;
    position: absolute;
    top: 60px;
    right: 80px;
    padding: 40px;
  }
  .hasRegistered {
    color: #000000;
    font-size: 26px;
  }
  .loginBtn {
    color: #ffffff;
    background: #007db8;
    width: 80px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    margin: 20px 0;
    cursor: pointer;
  }
  .hasRegistered {
    color: #000000;
    font-size: 26px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .form-control{
    background-color: #FFF;
    background-image: none;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    color: inherit;
    display: block;
    padding: 6px 12px;
    -webkit-transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
    transition: border-color .15s ease-in-out 0s,box-shadow .15s ease-in-out 0s;
    width: 100%;
    font-size: 14px;
    height: 34px;
  }
  .submitBtn {
    width: 100%;
    color: #ffffff;
    background-color: #008FD3;
    height: 40px;
  }
  fieldset {
    min-width: 0;
    padding: 0.35em .625em 0.35em;
    margin: 0px;
    border: 1px solid silver;
    margin-bottom: 18px;
    display: block;
    margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start: 0.35em;
    padding-inline-start: 0.75em;
    padding-inline-end: 0.75em;
    padding-block-end: 0.625em;
    min-inline-size: min-content;
  }
  legend {
    width: auto;
    border-bottom: 0px;
    font-size: 14px;
    margin-bottom: 0px;
    line-height: inherit;
    color: #333;
    border: 0;
    padding: 0;
    display: block;
  }
  .check {
    color: blue;
  }
  .checkReg {
    height: 28px;
  }
  .formRegistered {
    width: 100%;
    font-size: 14px;
    border: 0px;
    padding-left: 5px;
  }
</style>
